import { Routes } from "@angular/router";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { AboutComponent } from "./pages/about/about.component";
import { ReportingProcessComponent } from "./pages/reporting-process/reporting-process.component";
import { PressReleaseComponent } from "./pages/press-release/press-release.component";
import { ServiceInformationComponent } from "./pages/service-information/service-information.component";
import { QuestionsComponent } from "./pages/questions/questions.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { GetDetailNewComponent } from "./pages/get-detail-new/get-detail-new.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "/main",
    pathMatch: "full",
  },
  { path: "main", component: MainPageComponent },
  { path: "about", component: AboutComponent },
  { path: "reporting-process", component: ReportingProcessComponent },
  { path: "press-release", component: PressReleaseComponent },
  { path: "service-information", component: ServiceInformationComponent },
  { path: "questions", component: QuestionsComponent },
  { path: "contact", component: ContactComponent },
  { path: "detail", component: GetDetailNewComponent },

  
  {
    path: '**',
    component: PageNotFoundComponent,
  }
];
