import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthenService } from "app/services/authenService";
import { JwtService } from "app/services/jwt.service";

@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public menuItems2: any[];
  public user: any;
  public isAdmin: boolean = false;

  constructor(
    private authenService: AuthenService,
    private activeRoute: ActivatedRoute,

    private jwt: JwtService
  ) {}

  ngOnInit() {
  }

  groupBy<T>(array: T[], key: keyof T): { [key: string]: T[] } {
    return array.reduce((result, currentValue) => {
      const groupKey = currentValue[key] as unknown as string;
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {} as { [key: string]: T[] });
  }
}
