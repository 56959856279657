import { Component, OnInit } from "@angular/core";
import { newsDto } from "app/models/news/newsDto";
import { OnePageService } from "app/services/onePageService";

@Component({
  selector: "app-press-release",
  templateUrl: "./press-release.component.html",
  styleUrls: ["./press-release.component.scss"],
})
export class PressReleaseComponent implements OnInit {
  dataList: newsDto[] = [];

  constructor(private onePageService: OnePageService) {
    this.getNews();
  }

  ngOnInit() {}

  getNews() {
    this.onePageService.GetNews(1).subscribe(async (res: any) => {
      const result = await res;
      this.dataList = result.data;
      console.log("GetNews", result.data);
    });
  }

  onGetDetail(id:string){
    window.location.href = `/detail?id=` + id + "&&page=press-release";
  }
}
