export class newsDto {
    description: string;
    id: string;
    name: string;
    status: string;
    type_name: string;
    url: string;
    date_start: string;
    date_end: string;
    file_name: string;
}