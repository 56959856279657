<div class="breadcrumb">
  <a href="/" class="home-link">หน้าหลัก</a>
  <span class="separator">></span>
  <span class="current-page">ขั้นตอนการแจ้งขอรับบริการ</span>
</div>
<div class="row">
  <div class="col-12 text-center">
    <h2 class="text-coler-base">ขั้นตอนการแจ้งขอรับบริการ</h2>
  </div>
</div>
<div class="row">
  <div class="container">
    <div class="col-12 text-justify">
      <label for="text">1. ประชาชนจะต้องลงทะเบียนผู้ใช้ โดยคลิกที่ “ลงทะเบียน” จากนั้นป้อนข้อมูลลงทะเบียน ประกอบด้วย ชื่อผู้ใช้งาน และ รหัสผ่าน และ ข้อมูลส่วนตัว ประกอบด้วย เลขประจำตัวประชาชน คำนำหน้าชื่อ ชื่อ นามสกุล เพศ และอีเมล
      </label>
    </div>
    <div class="col-12 text-justify">
      <label for="text">2. เมื่อลงทะเบียนเรียบร้อย ให้คลิกที่แจ้งเรื่อง จากนั้นให้กรอกรายละเอียดของเรื่องที่ต้องการขอรับบริการ และรายละเอียดต่างๆ ของเรื่องที่ต้องการขอรับบริการ หรือสถานที่ที่ต้องการขอรับบริการ หน่วยงานที่ต้องการขอรับบริการ หรือสิ่งที่ต้องการให้กระทรวงดำเนินการและช่องทางการแจ้งกลับ
      </label>
    </div>
    <div class="col-12 text-justify">
      <label for="text">3. เลือกช่องทางการติดต่อกลับ เพื่อเป็นข้อมูลให้เจ้าหน้าที่ได้ติดต่อท่านกลับในภายหลัง
      </label>
    </div>
    <div class="col-12 text-justify">
      <label for="text">4. คลิกที่ปุ่มส่งเรื่อง โดยระบบจะส่งเรื่องไปยังเจ้าหน้าที่ต่อไป</label>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12 text-center">
    <h2 class="text-coler-base">ขั้นตอนการติดตามเรื่องขอรับบริการ</h2>
  </div>
</div>
<div class="row">
  <div class="container">
    <div class="col-12 text-justify">
      <label for="text">1. ประชาชนล็อกอินโดยใช้ ชื่อผู้ใช้งาน และ รหัสผ่าน ที่กำหนดไว้ก่อนหน้านี้
      </label>
    </div>
    <div class="col-12 text-justify">
      <label for="text">2. ระบบจะแสดงเรื่องที่ยื่นร้องเรียนเอาไว้ และ สถานะเรื่อง </label>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12 text-center">
    <h2 class="text-coler-base">แผนผังกระบวนการทำงาน ศูนย์บริการเกษตรพิรุณราช กระทรวงเกษตรและสหกรณ์</h2>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-12">
      <img src="/assets/images/Activity.png" class="img-fluid" alt="">
    </div>
  </div>
  
  
</div>


