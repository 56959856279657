import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { newsDto } from 'app/models/news/newsDto';
import { OnePageService } from 'app/services/onePageService';

@Component({
  selector: 'app-get-detail-new',
  templateUrl: './get-detail-new.component.html',
  styleUrls: ['./get-detail-new.component.scss']
})
export class GetDetailNewComponent implements OnInit {
  title_name:string = "";
  title:string = "";
  href: string;
  id: string;

  new: newsDto = new newsDto();
  

  constructor(private route: ActivatedRoute,private onePageService: OnePageService) { }

  ngOnInit() {
    this.href = window.location.href;
    
    if (this.href.includes("press-release")) {
      this.title = "ข่าวประชาสัมพันธ์";
    }
    else if (this.href.includes("service-information")) {
      this.title= "ข้อมูลบริการ";
    }

    this.route.queryParams.subscribe((params) => {
      this.id = params["id"];
      this.getData();
    });

  }

  getData(){
    this.onePageService.GetNewsDetail(this.id).subscribe(async (res: any) => {
      const result = await res;
      this.new = result.data;
    });
  }

}
