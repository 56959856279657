<div class="breadcrumb">
  <a href="/" class="home-link">หน้าหลัก</a>
  <span class="separator">></span>
  <span class="current-page">{{title}}</span>
  <span class="separator">></span>
  <span class="current-page text-coler-base">รายละเอียด</span>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 col-md-12 col-lg-12">
      <div class="card">
        <div class="card-header background-base">
         <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <h6>{{new?.name}}</h6>
          </div>
          <div class="col-9 col-md-9 col-lg-9">
            <span>เวลาเริ่ม&nbsp;:&nbsp;{{new?.date_start}}</span>   
            <span>&nbsp;&nbsp;&nbsp;&nbsp;เวลาสิ้นสุด&nbsp;:&nbsp;{{new?.date_end}}</span>
          </div>
          <div class="col-3 col-md-3 col-lg-3 text-right">
            <span>สถานะ&nbsp;:&nbsp;{{new?.status}}</span>
          </div>
         </div>
        </div>
        <div class="card-body">
          <img src="{{new?.file_name}}" class="card-img-top" alt="...">
          <p class="card-text text-justify">{{new?.description}}</p>
        </div>

        <div class="card-footer background-base">
          <div class="row">
           <div class="col-12 col-md-12 col-lg-12">
             <h6>{{new?.name}}</h6>
           </div>
          </div>
         </div>
      </div>
    </div>
  </div>
</div>
