
<div class="breadcrumb">
  <a href="/" class="home-link">หน้าหลัก</a>
  <span class="separator">></span>
  <span class="current-page">ติดต่อเรา</span>
</div>
<div class="row">
  <div class="col-12 text-center">
    <h2 class="text-coler-base">ติดต่อเรา</h2>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-12 col-lg-12 text-center">
    <h4 style="color: rgb(99, 125, 65);">สำนักบริหารกองทุนเพื่อช่วยเหลือเกษตรกรและรับเรื่องร้องเรียน สำนักงานปลัดกระทรวงเกษตรและสหกรณ์</h4>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-12 col-lg-12 text-center">
    <h6>เลขที่ 3 ถนนราชดำเนินนอก แขวงบ้านพานถม เขตพระนคร กรุงเทพฯ 10200</h6>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-12 col-lg-12 text-center">
    <h4><i class="fa-solid fa-phone" style="font-size: 20px;"></i> โทรศัพท์: <span style="color: rgb(92, 181, 154);">02-629-9091</span>, <span style="color: rgb(92, 181, 154);">02-629-9072</span></h4>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-12 col-lg-12 text-center">
    <h4><i class="fa-solid fa-message"style="font-size: 20px;"></i> โทรสาร: <span>02-629-8985</span></h4>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-12 col-lg-12 text-center">
    <h4><i class="fa-solid fa-envelope"style="font-size: 20px;"></i> Email: <span style="color: rgb(92, 181, 154);">{{'moacbfa@gmail.com'}}</span></h4>
  </div>
</div>

<div class="row">
  <div class="col-12 col-md-12 col-lg-12 text-center">
    <img src="/assets/images/Map.jpg" class="img-fluid" alt="">
  </div>
</div>

