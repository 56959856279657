<div class="container">
  <div class="row">
    <div class="col-12 col-md-12">
      <ngb-carousel>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/images/AW-phirunrat.png" class="d-block w-100" />
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/images/header02.png" class="d-block w-100" />
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/images/header03.png" class="d-block w-100" />
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12 col-md-12">
      <img src="assets/images/BgLogin.png" alt="Snow" class="d-block w-100" />
      <div class="centered">
        <div class="row">
          <div class="col-12 col-md-12">
            <h3>เข้าสู่ระบบ</h3>
          </div>
          <div class="col-12 col-md-12">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-danger w-100" (click)="onClickAgent()">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 14V22H4C4 17.5817 7.58172 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM21 17H22V22H14V17H15V16C15 14.3431 16.3431 13 18 13C19.6569 13 21 14.3431 21 16V17ZM19 17V16C19 15.4477 18.5523 15 18 15C17.4477 15 17 15.4477 17 16V17H19Z"
                    ></path>
                  </svg>
                  เข้าสู่ระบบสำหรับเจ้าหน้าที่
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-danger w-100"  (click)="onClickPerson()">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 640 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32zm288-32h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 32 0 0 1 64 0z"
                    ></path>
                  </svg>
                  เข้าสู่ระบบ / ลงทะเบียนสำหรับผู้รับบริการ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <h3 style="color: black">ขั้นตอนการแจ้งขอรับบริการของประชาชน</h3>
    </div>
    <div class="col-12">
      <img src="assets/images/step.png" class="d-block w-100" />
    </div>
    <div class="col-12">
      <h3 style="color: black">
        รายงานภาพรวมการให้บริการ ศูนย์บริการเกษตรพิรุญราช
      </h3>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h3 style="color: black">ข่าวประชาสัมพันธ์</h3>
            </div>
            <div class="col-12">
              <h5 style="color: black">ไม่มีข้อมูล</h5>
            </div>
            <div class="col-12">
              <button class="btn btn-danger">ดูทั้งหมด</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h3 style="color: black">ข้อมูลการบริการ</h3>
            </div>
            <div class="col-12">
              <h5 style="color: black">ไม่มีข้อมูล</h5>
            </div>
            <div class="col-12">
              <button class="btn btn-danger">ดูทั้งหมด</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12 col-md-12">
      <ngb-carousel>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/images/AW-phirunrat.png" class="d-block w-100" />
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/images/header02.png" class="d-block w-100" />
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/images/header03.png" class="d-block w-100" />
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
  <!-- <div class="row mt-2">
    <div class="col-12 col-md-12">
      <img src="assets/images/BgLogin.png" alt="Snow" class="d-block w-100" />
      <div class="centered">
        <div class="row">
            <div class="col-12 col-md-12">
                <h3>เข้าสู่ระบบ</h3>
            </div>
            <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-auto">
                        <button class="btn btn-danger w-100">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 14V22H4C4 17.5817 7.58172 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM21 17H22V22H14V17H15V16C15 14.3431 16.3431 13 18 13C19.6569 13 21 14.3431 21 16V17ZM19 17V16C19 15.4477 18.5523 15 18 15C17.4477 15 17 15.4477 17 16V17H19Z"></path></svg>
                            เข้าสู่ระบบสำหรับเจ้าหน้าที่
                        </button>
                    </div>  
                    <div class="col-auto">
                        <button class="btn btn-danger w-100">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32zm288-32h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 32 0 0 1 64 0z"></path></svg>
                            เข้าสู่ระบบ / ลงทะเบียนสำหรับผู้รับบริการ
                        </button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12 col-md-12">
      <img src="assets/images/BgLogin.png" alt="Snow" class="d-block w-100" />
      <div class="centered">
        <div class="row">
            <div class="col-12 col-md-12">
                <h3>เข้าสู่ระบบ</h3>
            </div>
            <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-auto">
                        <button class="btn btn-danger w-100">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 14V22H4C4 17.5817 7.58172 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM21 17H22V22H14V17H15V16C15 14.3431 16.3431 13 18 13C19.6569 13 21 14.3431 21 16V17ZM19 17V16C19 15.4477 18.5523 15 18 15C17.4477 15 17 15.4477 17 16V17H19Z"></path></svg>
                            เข้าสู่ระบบสำหรับเจ้าหน้าที่
                        </button>
                    </div>  
                    <div class="col-auto">
                        <button class="btn btn-danger w-100">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32zm288-32h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 32 0 0 1 64 0z"></path></svg>
                            เข้าสู่ระบบ / ลงทะเบียนสำหรับผู้รับบริการ
                        </button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
