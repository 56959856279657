<div class="breadcrumb">
  <a href="/" class="home-link">หน้าหลัก</a>
  <span class="separator">></span>
  <span class="current-page">คำถามที่พบบ่อย</span>
</div>
<div class="row">
  <div class="col-12 text-center">
    <h2 class="text-coler-base">คำถามที่พบบ่อย</h2>
  </div>
</div>

<div class="container-lg">
  <div id="accordion">
    <div class="card mt-3" *ngFor="let item of dataList; let i = index">
      <div class="card-header" id="heading{{item.id}}">
        <h5 class="mb-0">
          <button 
            class="btn btn-link" 
            (click)="toggleCollapse(i)"
            [attr.aria-expanded]="!isCollapsed[i]"
            aria-controls="collapseExample">
            <span class="text-coler-base"><i class="fa-regular fa-circle-question"></i> {{item.title}}</span>
          </button>
        </h5>
      </div>
  
      <div id="collapse{{item.id}}" 
           [ngbCollapse]="isCollapsed[i]" 
           class="collapse">
        <div class="card-body">
          <span>{{item.answer}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
