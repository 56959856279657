import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reporting-process',
  templateUrl: './reporting-process.component.html',
  styleUrls: ['./reporting-process.component.scss']
})
export class ReportingProcessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
