import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  moduleId: module.id,
  selector: "navbar-cmp",
  templateUrl: "navbar.component.html",
})
export class NavbarComponent implements OnInit {
  location: Location;

  public isCollapsed = true;
  @ViewChild("navbar-cmp", { static: false }) button;

  activeTab: number = 1;
  href: string;
  constructor(private element: ElementRef, private router: Router,private activatedRoute: ActivatedRoute,) {}

  ngOnInit() {
    var navbar: HTMLElement = this.element.nativeElement;
    this.href = window.location.href;

    if (this.href.includes("main")) {
      this.activeTab = 1;
    }
    else if (this.href.includes("about")) {
      this.activeTab = 2;
    }
    else if (this.href.includes("reporting-process")) {
      this.activeTab = 3;
    }
    else if (this.href.includes("press-release")) {
      this.activeTab = 4;
    }
    else if (this.href.includes("service-information")) {
      this.activeTab = 5;
    }
    else if (this.href.includes("questions")) {
      this.activeTab = 6;
    }
    else if (this.href.includes("contact")) {
      this.activeTab = 7;
    }
  }
  onSelectTabs(tabs: number) {
    this.activeTab = tabs;
    switch (this.activeTab) {
      case 1:
        this.router.navigate(["main"]);
        break;
      case 2:
        this.router.navigate(["about"]);
        break;
      case 3:
        this.router.navigate(["reporting-process"]);
        break;
      case 4:
        this.router.navigate(["press-release"]);
        break;
      case 5:
        this.router.navigate(["service-information"]);
        break;
      case 6:
        this.router.navigate(["questions"]);
        break;
      case 7:
        this.router.navigate(["contact"]);
        break;
    }
  }
}
