import { Component, OnInit } from '@angular/core';

import {environment} from '../../../environments/environment'
import { Router } from '@angular/router';


@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  webUrl:string;
  constructor(  private route: Router,) { 
    this.webUrl = environment.webUrl;
  }

  ngOnInit() {
    console.log("xx",this.webUrl);
  }

  onClickAgent(){
   // window.location.href = this.webUrl + "?code_type=agent";
    window.open(this.webUrl + "?code_type=agent" , '_blank');
  
  }

  onClickPerson(){
   // window.location.href = this.webUrl + "?code_type=person";

    window.open(this.webUrl + "?code_type=person" , '_blank');
  }

}
