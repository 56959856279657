<div class=" mt-5">
<footer class="footer mt-5">
    <div class="container text-center">
      <h4 class="text-name">สำนักบริหารกองทุนเพื่อช่วยเหลือเกษตรกรและรับเรื่องร้องเรียน</h4>
      <p>สำนักงานปลัดกระทรวงเกษตรและสหกรณ์</p>
      <p>เลขที่ 3 ถนนราชดำเนินนอก แขวงบ้านพานถม เขตพระนคร กรุงเทพฯ 10200</p>
      <p>โทรศัพท์: 02-629-9091 , 02-629-9072</p>
      <p>โทรสาร: 02-629-8985</p>
      <p>อีเมล: <a href="mailto:moacbfa@gmail.com">moacbfa@gmail.com</a></p>
      <p>Copyright © 2023 กระทรวงเกษตรและสหกรณ์. All rights reserved.</p>
    </div>
  </footer>
</div>