import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

    tinyAlert() {
      Swal.fire('Hey there!');
    }
  
    // successNotification() {
    //   Swal.fire('Hi', 'We have been informed!', 'success')
    // }
    successNotification(title:string,detail:string) {
      Swal.fire(title, detail, 'success')
    }
  
    errorNotification(title:string,detail:string) {
      Swal.fire(title, detail, 'error')
    }
  
    warnningNotification(title:string,detail:string) {
      Swal.fire(title, detail, 'warning')
    }
    alertConfirmation() {
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: 'Are you sure?',
          text: 'This process is irreversible.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, go ahead.',
          cancelButtonText: 'No, let me think'
        }).then((result) => {
          resolve(result.value);
        });
      });
    }
    warn(){
      Swal.fire({
        title: '<strong>HTML <u>example</u></strong>',
        icon: 'warning',
        html:
          'You can use <b>bold text</b>, ' +
          '<a href="//sweetalert2.github.io">links</a> ' +
          'and other HTML tags',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Great!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText:
          '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
      })
    }
  
    async swalConfirm(
      title: string,
      text: string,
      html?: string
    ): Promise<boolean> {
      let confirm = false;
      if (!html) {
        await Swal.fire({
          title,
          text,
          cancelButtonText:'ไม่',
          showCancelButton: true,
          confirmButtonText: 'ใช่',
        }).then((result) => {
          confirm = result.value as boolean;
        });
        if (confirm) {
          return true;
        } else {
          return false;
        }
      } else {
        await Swal.fire({
          title,
          text,
          html,
          cancelButtonText:'ไม่',
          showCancelButton: true,
          confirmButtonText: 'ใช่',
        }).then((result) => {
          confirm = result.value as boolean ;
        });
        if (confirm) {
          return true;
        } else {
          return false;
        }
      }
    }

  }
  