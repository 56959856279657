import { Injectable } from "@angular/core";
import { HttpService } from "./http.services";
import { BehaviorSubject, Observable, catchError, map, throwError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AuthenService {
  
  
}
