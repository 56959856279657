<nav class="navbar navbar-expand-lg bg-navigator">
  <div
    class="collapse navbar-collapse"
    id="navbarNav"
    style="justify-content: center"
  >
    <img src="assets/images/LogoName.png" alt="" style="margin-right: 50px" />
    <ul class="navbar-nav">
      <li class="nav-item" [ngClass]="{'active-tabs': activeTab == 1 }" >
        <span class="nav-link" (click)="onSelectTabs(1)">หน้าแรก</span>
      </li>
      <li class="nav-item" [ngClass]="{ 'active-tabs': activeTab == 2 }">
        <span class="nav-link" (click)="onSelectTabs(2)">เกี่ยวกับเรา</span>
      </li>
      <li class="nav-item" [ngClass]="{ 'active-tabs': activeTab == 3 }">
        <span class="nav-link" (click)="onSelectTabs(3)">ขั้นตอนการแจ้งเรื่อง</span>
      </li>
      <li class="nav-item" [ngClass]="{ 'active-tabs': activeTab == 4 }">
        <span class="nav-link" (click)="onSelectTabs(4)">ข่าวประชาสัมพันธ์</span>
      </li>
      <li class="nav-item" [ngClass]="{ 'active-tabs': activeTab == 5 }">
        <span class="nav-link" (click)="onSelectTabs(5)">ข้อมูลบริการ</span>
      </li>
      <li class="nav-item" [ngClass]="{ 'active-tabs': activeTab == 6 }">
        <span class="nav-link" (click)="onSelectTabs(6)">คำถามที่พบบ่อย</span>
      </li>
      <li class="nav-item" [ngClass]="{ 'active-tabs': activeTab == 7 }">
        <span class="nav-link" (click)="onSelectTabs(7)">ติดต่อเรา</span>
      </li>
    </ul>
  </div>
</nav>
