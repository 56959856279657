import { Injectable } from "@angular/core";
import { HttpService } from "./http.services";
import { BehaviorSubject, Observable, catchError, map, throwError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { OnePageApiPath } from "./_constants/apiConstants";

@Injectable({
  providedIn: "root",
})
export class OnePageService {
  constructor(private http: HttpService) {}

  GetNews(type: number) {
    return this.http.get(`${OnePageApiPath.GetNews}` + "?type_id=" +type, false).pipe(
      map(async (responseBody) => {
        const res = await responseBody;
        return res;
      })
    );
  }

  GetNewsDetail(id: string) {
    return this.http.get(`${OnePageApiPath.GetNewDetail}/`+ id, false).pipe(
      map(async (responseBody) => {
        const res = await responseBody;
        return res;
      })
    );
  }


  Getfaqs() {
    return this.http.get(`${OnePageApiPath.Getfaqs}`, false).pipe(
      map(async (responseBody) => {
        const res = await responseBody;
        return res;
      })
    );
  }
}
