import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpService } from "./services/http.services";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { JwtInterceptor } from "./services/jwt.interceptor";
import { ErrorInterceptor } from "./services/error.interceptor";

import { SharedModule } from "./shared/shared.module";

import { NgSelectModule } from "@ng-select/ng-select";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { AboutComponent } from "./pages/about/about.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { PressReleaseComponent } from "./pages/press-release/press-release.component";
import { QuestionsComponent } from "./pages/questions/questions.component";
import { ReportingProcessComponent } from "./pages/reporting-process/reporting-process.component";
import { ServiceInformationComponent } from "./pages/service-information/service-information.component";
@NgModule({
  declarations: [		
    AppComponent,
    MainPageComponent,
    AboutComponent,
    ReportingProcessComponent,
    PressReleaseComponent,
    ServiceInformationComponent,
    QuestionsComponent,
    ContactComponent,
    PageNotFoundComponent
   ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: false
    }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    NgSelectModule
  ],
  exports: [RouterModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
    },
    
    HttpService],
  bootstrap: [AppComponent]
})
export class AppModule { }
