import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders,
  HttpBackend,
  HttpEvent,
} from "@angular/common/http";
import { EMPTY, Observable, catchError, map, retry } from "rxjs";
import { environment } from "environments/environment";
import { SweetAlertService } from "./sweet-alert.service";

declare let bootbox: any;

@Injectable({
  providedIn: "root",
})
export class HttpService {
  public _BASE_API = environment.apiUrl;

  constructor(public httpClient: HttpClient, public handler: HttpBackend,  private swal: SweetAlertService) {}

  get<T>(url: string, loading: boolean = true): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.set("loading", loading ? "true" : "false");

    return this.httpClient
      .get<HttpResponse<T>>(this._BASE_API + url, {
        headers: headers,
        withCredentials: true,
        observe: "response",
      })
      .pipe(
        map((res: any) => {
          if (res === undefined || res === null) {
            throw new Error("HttpResponse undefined!");
          }

          if (res.status === undefined || res.status === null) {
            throw new Error("HttpResponse.status undefined!");
          }

          if (
            res.status !== 204 &&
            (res.body === undefined || res.body === null)
          ) {
            throw new Error("HttpResponse.body undefined!");
          }

          return res.body as T;
        }),
        retry(2),
        catchError((err) => this.handleError(err))
      );
  }

  post<T>(url: string, data: any, loading: boolean = true): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("loading", loading ? "true" : "false");
    return this.httpClient
      .post<HttpResponse<T>>(this._BASE_API + url, data, {
        headers: headers,
        withCredentials: true,
        observe: "response",
      })
      .pipe(
        map((res: any) => {
          if (res === undefined || res === null) {
            throw new Error("HttpResponse undefined!");
          }

          if (res.status === undefined || res.status === null) {
            throw new Error("HttpResponse.status undefined!");
          }

          if (
            res.status !== 204 &&
            (res.body === undefined || res.body === null)
          ) {
            throw new Error("HttpResponse.body undefined!");
          }

          return res.body as T;
        }),
        catchError((err) => this.handleError(err, data))
      );
  }

  put<T>(url: string, data: any, loading: boolean = true): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.set("loading", loading ? "true" : "false");
    return this.httpClient
      .put<T>(this._BASE_API + url, data, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        map((res: any) => {
          if (res === undefined || res === null) {
            throw new Error("Value expected!");
          }
          return res as T;
        }),
        catchError((err) => this.handleError(err, data))
      );
  }

  patch<T>(url: string, data: any, loading: boolean = true): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.set("loading", loading ? "true" : "false");
    return this.httpClient
      .patch<T>(this._BASE_API + url, data, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        map((res: any) => {
          if (res === undefined || res === null) {
            throw new Error("Value expected!");
          }
          return res as T;
        }),
        catchError(this.handleError)
      );
  }

  delete<T>(url: string, loading: boolean = true): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.set("loading", loading ? "true" : "false");

    return this.httpClient
      .delete<T>(this._BASE_API + url, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        map((res: any) => {
          if (res === undefined || res === null) {
            throw new Error("Value expected!");
          }
          return res as T;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: any, data: any = null) {
    let errorMessage = "Unknown error!";
    let errorStatusText = "Unknown error!";
    let errorStatus = 0;
    let errorUrl: any;
    console.log("HttpErrorResponse : ", error);
    // let bootbox: any;
    if (error.error instanceof ErrorEvent) {
      console.log("HttpErrorResponse : ", error);
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = error.message;
      errorStatus = error.status;
      errorStatusText = error.statusText;
    }
    errorUrl = error?.url;
    if (errorStatus == 401 || errorStatus == undefined) {
      console.warn("handleError : " + errorMessage);
      this.swal.errorNotification(
        `error status: ${errorStatus}`,errorMessage
      );
      return EMPTY;
      // return Observable. .empty<HttpEvent<any>>();
      // return throwError('handleError 401: '+errorMessage);
    }

    if (error?.error?.errors) {
      errorMessage = JSON.stringify(error?.error?.errors);
    }
    console.log("errors data : ", data);
    console.log("errorStatus : ", errorStatus);

    try {
      if (errorStatus == 400) {
        console.log("LogHttpError 400: ");
        this.swal.errorNotification(
          `error status: ${errorStatus}`,errorMessage
        );
      }
    } catch {}

    try {
      if (errorStatus == 500) {
        console.log("LogHttpError 500: ");
        this.swal.errorNotification(
          `error status: ${errorStatus}`,errorMessage
        );
      }
    } catch {}


    var url = error.url;

    if (typeof bootbox !== "undefined") {
      bootbox.confirm({
        title:
          "<i class='fal fa-exclamation-circle text-danger mr-2'></i>" +
          (errorStatus + " " + errorStatusText) +
          " !",
        // title: "<i class='fal fa-exclamation-circle text-danger mr-2'></i> พบข้อผิดพลาด !",
        // message: '<span>ระบบไม่สามารถทำงานต่อได้ กรุณา โหลดหน้าใหม่.. </span>',
        message:
          "<span>" +
          errorUrl +
          "</span><br></span><span>" +
          errorMessage +
          "</span>",
        centerVertical: true,
        swapButtonOrder: true,
        buttons: {
          confirm: {
            label: "Reload",
            className: "btn-danger shadow-0",
          },
          cancel: {
            label: "Close",
            className: "btn-default",
          },
        },
        className: "modal-alert",
        closeButton: false,
        callback: (result: boolean) => {
          if (result === true) {
            window.location.reload();
          }
        },
      });
    }
    console.warn("handleError : " + errorMessage);
    return EMPTY;
  }
}
