<div class="breadcrumb">
  <a href="/" class="home-link">หน้าหลัก</a>
  <span class="separator">></span>
  <span class="current-page">ข่าวประชาสัมพันธ์</span>
</div>
<div class="row">
  <div class="col-12 text-center">
    <h2 class="text-coler-base">ข่าวประชาสัมพันธ์</h2>
  </div>
</div>
<!-- <div class="row">
  <div class="col-12 col-md-12 col-lg-12">
    <table class="table table-striped w-100" style="width: 100%;">
      <thead class="text-center">
        <tr class="background-base">
         <th>ลำดับ</th>
         <th>เรื่อง</th>
         <th>รายละเอียด</th>
         <th>ประเภท</th>
         <th>วันที่</th>
         <th>สถานะ</th>
        </tr>
      </thead>
      <tbody>
        <tr  *ngFor="let item of dataList; index as i" (click)="onGetDetail(item.id)">
          <td  class="text-center">{{i+1}}</td>
          <td>{{item.name}}</td>
          <td>{{item.description}}</td>
          <td>{{item.type_name}}</td>
          <td>{{item.date_start}}</td>
          <td>{{item.status}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div> -->

<div class="container">
  <div class="row">
    <div class="col-4 mt-2" *ngFor="let item of dataList; index as i" (click)="onGetDetail(item.id)" >
      <div class="card" style="height: 100%;">
        <img  [src]="item.file_name" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title text-coler-base">{{item.name}}</h5>
          <p class="card-text">{{item.description}}</p>
          <i class="fa-regular fa-calendar"></i> {{item.date_start}}
        </div>
      </div>
    </div>
  </div>
</div>