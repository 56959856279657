import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  first,
  switchMap,
  switchMapTo,
  take,
} from 'rxjs/operators';
import { result } from 'lodash';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { AuthenService } from './authenService';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenService,
    private router: Router,
  ) {}

  private request401Count = 1;
  private isRefreshing = false;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let interceptObservable = new Subject<HttpEvent<any>>();

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {

          // ==========================================================
          // Logic ชุดนี้ คือ ถ้าเกิด Error ให้ Redirect ไปที่หน้า CookieLogin
          // โดยจะ set ตัว 'stopl' ไว้ที่ localstorage เพื่อให้ Component cookie-login
          // เช็ค เพื่อกัน Loop redirect
            const currentLocation = origin + this.router.url;
            // localStorage.setItem('stopl', 'true');
            if(currentLocation.search('/cookie-login') == -1) {
            //  this.authenticationService.logout();
              this.router.navigate(['/cookie-login'], {
                queryParams: { redirect: currentLocation }
              });
            }
          // ==========================================================


        }
        const error = err.error.message || err.statusText;
        return throwError(err);
      })
    );
  }

  // private addToken(request: HttpRequest<any>): HttpRequest<any> {
  //   const currentUser = this.authenticationService.currentUserValue;
  //   return request.clone({
  //     setHeaders: {
  //       Authorization: `Bearer ${currentUser.access_token}`,
  //     },
  //   });
  // }

  getUrlContent(url: any): any {
    const urlData = new URL(url);
    return urlData;
  }
}
