import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbCarouselModule, NgbModule, NgbPaginationModule, NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
  declarations: [],
  imports: [
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    CommonModule,
    NgSelectModule,
    NgbCarouselModule
],
  exports: [
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    CommonModule,
    NgSelectModule,
    BrowserModule
    
],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
