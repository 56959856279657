import { Component, OnInit } from "@angular/core";
import { QuestionDto } from "app/models/question/questionDto";
import { OnePageService } from "app/services/onePageService";

@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.scss"],
})
export class QuestionsComponent implements OnInit {
  constructor(private onePageService: OnePageService) {
    this.getQuestion();

  }
  dataList: QuestionDto[] = [];
  isCollapsed: boolean[] = [];

  ngOnInit() {}

  getQuestion() {
    this.onePageService.Getfaqs().subscribe(async (res: any) => {
      const result = await res;
      this.dataList = result.data;
      this.isCollapsed = this.dataList.map(() => false);
    });
  }
  
  toggleCollapse(index: number) {
    this.isCollapsed[index] = !this.isCollapsed[index];
  }
}
