import { Component, OnInit } from '@angular/core';
import { newsDto } from 'app/models/news/newsDto';
import { OnePageService } from 'app/services/onePageService';

@Component({
  selector: 'app-service-information',
  templateUrl: './service-information.component.html',
  styleUrls: ['./service-information.component.scss']
})
export class ServiceInformationComponent implements OnInit {

  dataList: newsDto[] = [];

  constructor(private onePageService: OnePageService) {
    this.getNews();
  }

  ngOnInit() {}

  getNews() {
    this.onePageService.GetNews(2).subscribe(async (res: any) => {
      const result = await res;
      this.dataList = result.data;
      console.log("GetNews", result.data);
    });
  }

  onGetDetail(id:string){
    window.location.href = `/detail?id=` + id + "&&page=service-information";
   }

}
