import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenService } from './authenService';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;
  access_token: string = "xxx";

  constructor(private authenService: AuthenService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    //let currentUser = JSON.parse(localStorage.getItem('user'));
    
   // const isLoggedIn = currentUser && currentUser.access_token;
    const isLoggedIn = !!localStorage.getItem('access_token');
    const access_token = localStorage.getItem('access_token');
    //console.log("access_token => " + access_token);
    if (isLoggedIn) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer '+ access_token,
        },
        body: request.body
      });
    }
    return next.handle(request);
  }

  isIsoDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return this._isoDateFormat.test(value);
    } return false;
  }
  convert(body: any) {
    if (body === null || body === undefined) {
      return body;
    }
    if (typeof body !== 'object') {
      return body;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIsoDateString(value)) {
        body[key] = new Date(value);
      } else if (typeof value === 'object') {
        this.convert(value);
      }
    }
  }
}
